<template>
  <div class="payments-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/digital-wallet">Digital Wallet tansactions</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.booking_number }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="pl-lg-9 px-sm-7 px-3 py-sm-5 py-3">
        <div class="d-flex align-center" v-if="data.customer">
          <v-avatar color="gray lighten-2" size="52">
            <v-img v-if="data.customer.picture != null" :src="data.customer.picture"></v-img>
            <div v-else class="d-flex align-center">
              <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
            </div>
          </v-avatar>
          <div class="ms-3">
            <div class="text-h6 font-weight-bold">{{ data.customer.first_name }} {{ data.customer.last_name }}</div>
            <div v-if="role != 'manager'">
              <router-link :to="`/customers/${data.customer.id}`">Open profile</router-link>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">{{ data.booking_number }}</div>
        <div class="mb-3">
          <span class="text--text">Transaction date:</span>
          {{
            new Date(data.created_at).toLocaleString('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          }}
        </div>
        <v-card flat class="width100" max-width="400">
          <div v-if="data.booking_id != null">
            <div class="font-weight-bold mt-5">Trip Booking Number</div>
            <v-card flat min-height="48" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                {{ data.booking_number ? data.booking_number : '' }}
              </div>
              <v-spacer></v-spacer>
              <v-btn v-if="data.booking_id" :to="`/trip-bookings/${data.booking_id}/general`" target="_blank" icon small>
                <v-icon class="f18" color="primary darken-3">mdi-open-in-new</v-icon>
              </v-btn>
            </v-card>
          </div>
          <div v-if="data.experience_booking_id != null">
            <div class="font-weight-bold mt-5">Experience Booking Number</div>
            <v-card flat min-height="48" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                {{ data.experience_booking_number ? data.experience_booking_number : '' }}
              </div>
              <v-spacer></v-spacer>
              <v-btn
                v-if="data.experience_booking_id"
                :to="`/promotions/${data.experience_booking_id}/campaign-details`"
                target="_blank"
                icon
                small
              >
                <v-icon class="f18" color="primary darken-3">mdi-open-in-new</v-icon>
              </v-btn>
            </v-card>
          </div>
          <div class="font-weight-bold mt-5">Type</div>
          <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
            <div class="text--text text-truncate text-capitalize">{{ data.type }}</div>
          </v-card>
          <div v-if="data.refill_code != null">
            <div class="font-weight-bold mt-5">Refill code used</div>
            <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.refill_code }}</div>
            </v-card>
          </div>
          <div v-if="data.reward_campaign != null">
            <div class="font-weight-bold mt-5">Promo campaign title</div>
            <v-card flat min-height="48" class="gray lighten-4 px-3 py-2 d-flex align-center mt-2">
              <div class="text--text text-truncate">
                {{ data.reward_campaign ? data.reward_campaign.name : '' }}
              </div>
              <v-spacer></v-spacer>
              <v-btn v-if="data.reward_campaign" :to="`/promotions/${data.reward_campaign.id}/campaign-details`" target="_blank" icon small>
                <v-icon class="f18" color="primary darken-3">mdi-open-in-new</v-icon>
              </v-btn>
            </v-card>
            <div class="font-weight-bold mt-5">Promo campaign code</div>
            <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
              <div class="text--text text-truncate">{{ data.reward_campaign ? data.reward_campaign.code.code : '' }}</div>
            </v-card>
          </div>
          <div class="font-weight-bold mt-5">Amount of reward points</div>
          <v-card flat height="50" class="gray lighten-4 px-3 d-flex align-center mt-2">
            <div class="text--text text-truncate">{{ data.amount / 100 }}</div>
            <v-spacer></v-spacer>
            <div
              v-if="data.type == 'authorization' || data.type == 'enrollment' || data.type == 'refill' || data.type == 'reward'"
              class="text-center"
            >
              <v-icon color="success">mdi-arrow-up</v-icon>
              <div class="text--text f8">Enrolled</div>
            </div>
            <div v-if="data.type == 'charge'" class="text-center">
              <v-icon color="error">mdi-arrow-down</v-icon>
              <div class="text--text f8">Charged</div>
            </div>
          </v-card>
        </v-card>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  mounted() {
    this.$store.dispatch('getWalletItem');
  },
  computed: {
    data() {
      return this.$store.getters.wallet;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setWalletItem');
  },
};
</script>
